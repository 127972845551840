// passa palette
$teal: #44B3C4;
$blue1: #7AB4B1;
$blue2: #6FE8E1;
$beige: #DDD98D;
$yellow: #FFF870;
$pink: #FF3D54;
$orange1: #DA8444;
$orange2: #FF7D1C;
$gray: #F0F0F0;
$olive: #D5E281;
$fb-blue: #1877F2;
$dark-gray: #a9a9a9;
$gray1: #EDEDED;
$gray2: #E0E1E2;
$background-gray: #DADADB;

$black: rgba(0,0,0,1);
$black-4: rgba(0,0,0,0.4);
$black-6: rgba(0, 0, 0, 0.6);
$black-7: rgba(0,0,0,0.7);

$latoregular: 'latoregular';
$latomedium: 'latomedium';
$latosemibold: 'latosemibold';
$latobold: 'latobold';
$latoblack: 'latoblack';
$latoheavy: 'latoheavy';
@import "../../bower/normalize-scss/_normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import './fonts.scss';
@import './variables.scss';

$laptop-breakpoint: 1200px;
$big-tablet-breakpoint: 1024px;
$tablet-breakpoint: 768px;



html, body {
	height: 100%;
	scroll-behavior: smooth;
}
body {
	margin: 0;
	padding: 0;
	padding-right: 0px!important;
	font-family: $latoregular;
}
body.modal-open-noscroll
{
	padding-right: 0px!important;
    margin-right: 0!important;
    overflow: hidden;
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
}
ul {
	padding-left: 0;
}
li {
	list-style: none;
}
.margin-30 {
	margin: 30px auto;
}
.role {
	font-size: 13px;
	text-transform:uppercase;
	font-family: $latobold;
	&.manager {
		color: $teal;
	}
	&.facilitator {
		color: $orange1;
	}
	&.technical {
		color: $olive;
	}
}

.btn { 
	border-radius: 35px;
	padding: 9px 20px;
	outline: none!important;
	font-family: $latobold;
	&:hover, &:focus {
		outline: none;
	}
	&.btn-transparent {
		border: none;
		background: transparent;
		outline: none;
		box-shadow: none;
		&:hover, &:focus {
			background: transparent;
			outline: none;
		}
		&.nopadding{
			padding: 0 !important;
		}
	}

	&.btn-pink {
		background: $pink;
		color: white;
		border: 2px solid $pink;
		&:hover {
			background: white;
			color: $pink;
		}
	}

	&.btn-orange {
		background: $orange2;
		color: white;
		border: 2px solid $orange2;
		&:hover {
			background: white;
			color: $orange2;
		}
	}

	&.btn-teal {
		background: $teal;
		color: white;
		border: 2px solid $teal;
		&:hover {
			background: white;
			color: $teal;
		}
	}


	&.btn-gray {
		background: $background-gray;
		color: $black-4;
		&:hover {
			background: $black-4;
			color: $background-gray;
		}
	}

	&.btn-pink-o {
		background: transparent;
		color: $pink;
		border: 3px solid $pink;
		&:hover {
			background: $pink;
			color: white;
		}
	}
	&.btn-white-o {
		background: transparent;
		color: white;
		border: 3px solid white;
		&:hover {
			background: white;
			color: #333333;
		}
	}
	&.btn-icon {
		border-radius: 100%!important;
		padding: 0 5px;
		font-size: 20px;
	}
	&.btn-close {
		background: #3C3C3C;
		color: white!important;
		padding: 2px 5px;
	}
	&.btn-facebook {
		border: 2px solid $fb-blue;
		background: transparent;
		color: $fb-blue;
		position: relative;
		&:hover {
			background: $fb-blue;
			color: white;
		}
		.icon {
			position: absolute;
			top: 4px;
			left: 5px;
			img {
				max-width: 30px;
			}
		}
	}
}

.container-1000{
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 30px;
}

.menu-wrap {
	background: white;
	position: fixed;
	top: 0;
    width: 100vw;
	height: 100%;
	z-index: 1051;
	-webkit-transform: translate3d(-100vw,0,0);
    transform: translate3d(-100vw,0,0);
    -webkit-transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	padding: 30px;
	@media (min-width: $tablet-breakpoint) {
		max-width: 350px;
	}
	.back {
		position: absolute;
		top: 20px;
		right: 17px;
		.btn {
			color: $pink;
			font-size: 20px;
			padding: 0;
		}
	}
	.logo {
		text-align: center;
		img {
			max-height: 115px;
		}
	}
	.menu {
		li {
			border-bottom: 1px solid rgba(255, 61, 84, 0.3);
			text-align: center;
			&:last-child {
				border-bottom: none;
			}
			a {
				font-size: 37px;
				color: $pink;
				font-family: $latoblack;
				&.active, &:hover {
					color: $teal;
				}
			}
		}
	}
	.bottom {
		align-self: flex-end;
		.btn {
			width: 100%;
		}
	}
	.grid {
		display: grid;
		grid-gap: 10px;
		// height: 100%;
	}
}

.show-menu {
	.menu-wrap {
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
		-webkit-transition: -webkit-transform 0.8s;
		transition: transform 0.8s;
		-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
		transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	}
}

.content-wrap {
	overflow: hidden;
	margin-top: 60px;
	.container {
		padding: 0 30px;
	}
	.container-20 {
		padding: 0 20px;
	}
	.container-15 {
		padding: 0 15px;
	}
	@media (max-width: 320px) {
		.container {
			padding: 20px;
		}
	}
}
.navbar-default {
	background: white;
	border: none;
	border-radius: 0;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	margin-bottom: 0;
	.navbar-header {
		width: 100%;
		height: 60px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
		&:before {
			content: unset;
		}
		.btn {
			justify-self: flex-start;
			color: $pink;
			font-size: 18px;
		}
		.navbar-brand {
			padding: 0;
			justify-self: center;
			height: unset;
			img {
				max-height: 60px;
			}
		}
	}
}

#loginModal, 
#passwordModal,
#filterModal, 
#filterRoleModal, 
#filterCountryModal, 
#facilitatorModal,
#partnerModal,
#sponsorModal,
#messageModal,
#storyForm,
#filterStoriesModal {
	// z-index: 1052;
	.forgot-password {
		margin-bottom: 25px;
	}
	.btn-facebook {
		margin-top: 15px;
	}
	.close {
		position: absolute;
		top: 25px;
		right: 20px;
		line-height: 1px;
		outline: none;
	}
	.modal-dialog {
		margin: 0;
		height: 100%;
		.close {
			color: #3C3C3C;
			opacity: 0.6;
			z-index: 99;
		}
		.modal-header {
			border-bottom: none;
		}
		.modal-content {
			box-shadow: none;
			border-radius: 0;
			border: none;
			height: 100%;
			overflow: auto;
			.login-card	{
				margin-top: 30px;
				border-radius: 35px;
				.login-header {
					border-top-left-radius: 35px;
					border-top-right-radius: 35px;
					border: 1px solid #D8D8DB;
					padding: 20px;
					.logo {
						text-align: center;
						img {
							max-height: 100px;
						}
					}
					.header {
						font-size: 28px;
						font-family: $latobold;
						text-align: center;
					}
				}
				.login-body {
					padding: 25px;
					border: 1px solid #D8D8DB;
					border-top: none;
					background: #ececec;
					border-bottom-left-radius: 35px;
					border-bottom-right-radius: 35px;
					.form-group {
						margin-bottom: 20px;
						.form-control {
							background: white;
							border-radius: 35px;
							height: 42px;
							padding: 10px 20px;
							border: 1px solid #707070;
							&:focus { 
								box-shadow: none;
							}
						}
					}
					.btn {
						width: 100%;
					}
				}
			}
		}
	}
}
.filter-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	.country {
		font-family: $latobold;
		justify-self: flex-end;
	}
}
.select2 {
	display: block;
}
.select2-container--default {
	.select2-selection--single {
		height: 38px;
		.select2-selection__rendered {
			line-height: 38px;
			font-family: $latomedium;
		}
		.select2-selection__arrow {
			height: 38px;
		}
	}
	.select2-selection--multiple {
		border: 1px solid #DEDEDF;
		border-radius: 4px;
		outline: none;
		height: 38px;
	}
}
#filterModal, 
#filterStoriesModal,
#storyForm,
#filterRoleModal,
#facilitatorModal,
#partnerModal,
#sponsorModal,
#messageModal,
#filterCountryModal {
	.overlay {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: rgba(255,255,255,1);
		background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0.9)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
	}
	label {
		font-size: 13px;
		font-family: $latobold;
		color: #333333;
	}
	
	
	.modal-content {
		background: rgba(255,255,255,1);
		background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0.9)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%);
		padding: 45px;
		.modal-body {
			margin-top: 20px;
		}
		.filter-title {
			font-size: 18px;
			text-transform: uppercase;
			font-family: $latobold;
			margin-bottom: 20px;
		}
		.filters {
			li {
				border-bottom: 1px solid rgba(255, 61, 84, 0.3);
				padding: 10px 0;
				&:last-child {
					border-bottom: none;
				}
				a {
					font-size: 24px;
					color: $pink;
					font-family: $latomedium;
				}
			}
		}
	}
	
}
.filter-country {
	right: 0;
	left: unset;
	li {
		border-bottom: none!important;
		a {
			display: grid;
			grid-template-columns: 45px 1fr;
			grid-gap: 15px;
			align-items: center;
			margin-bottom: 5px;
		}
		&:last-child {
			.name {
				border-bottom: 0;
			}
		}
		.name {
			border-bottom: 1px solid rgba(255, 61, 84, 0.3);
			font-family: $latomedium;
			font-size: 24px;
			color: $pink;
			height: 45px;
			line-height: 45px;
		}
		.image {
			width: 45px;
			height: 45px;
			overflow: hidden;
			border-radius: 100%;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

#partnerModal {
	background: #ffffff;
	.modal-content {
		background:rgba(255, 125, 28, 0.5);
		
	}
}

#sponsorModal, #messageModal {
	background: white;
	.modal-content {
		background: rgba(111, 232, 225, 0.5);
	}
}

#facilitatorModal {
	background: white;
	.modal-content {
		background: rgba(255, 61, 84, 0.2);
	}
}

#partnerModal,
#messageModal,
#sponsorModal,
#facilitatorModal {
	.modal-title {
		font-family: $latoblack;
		font-size: 26px;
		margin-bottom: 10px;
	}
}

#helpModal,
#publishModal,
#contactModal{
	.modal-dialog{
		height: 100%;
		.modal-content{
			// background-color: lightblue;
			min-width: 0;
			border-radius: 25px;
			box-shadow: none;
			border: none;
			.modal-header{
				display: flex;
				justify-content: flex-end;
				border-bottom: none;
				.skip{
					color: $dark-gray;
					background: transparent;
					box-shadow: none;
				}
			}

			.modal-body{
				padding: 15px 35px;
				h2{
					text-align: center;
					font-family: $latosemibold;
					margin-top: 0px;
				}
				.image{
					display: grid;
					justify-content: center;
					min-width:100%;
				}
				.button-container{
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin-top: 20px;
					margin-bottom: 20px;
					.help-links{
						background: #EFEFEF;
						margin-top: 15px;
						margin-bottom: 15px;
						font-weight: 800;
						color: $black-7;
					}
				}
				.buttons{
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-gap: 20px;
					.help-links{
						margin-top: 15px;
						margin-bottom: 5px;
						font-weight: 800;
						color: $black-7;
					}
					.left{
						justify-self: end;
					}
					.right{
						justify-self: start;
					}
				}
			}
	
		}
	}
}

#contactModal{
	.modal-dialog{
		.modal-content{
			.modal-header{
				padding-bottom: 0px;
			}
			.modal-body{
				padding: 15px 35px;
				h2{
					font-weight: 800;
					text-align: center;
					font-family: $latosemibold;
					margin-top: 0px;
					margin-bottom: 5px;
				}
				p{
					text-align: center;
				}
				.form-container{
					.form-group{
						.label{
							padding-left: 0px;
							font-size: 14px;
							font-family: $latosemibold;
							color: black;
						}
						.parsley-errors-list{
							color: red;
							font-size: 12px;
						}
					}
					.send-container{
						display: grid;
						justify-content: center;
						margin-top: 20px;
						margin-bottom: 20px;
						.send-message{
							background-color: $pink;
							color: white;
						}
					}
				}
				
			}
	
		}
	}
}

.badge-modal,
#introModal,
#introHelpModal,
#helpModal,
#publishModal,
#contactModal{
	.modal-dialog{
		height: 100%;
		.modal-content{
			// background-color: lightblue;
			min-width: 0;
			border-radius: 25px;
			box-shadow: none;
			border: none;
			.modal-header{
				display: flex;
				justify-content: flex-end;
				border-bottom: none;
				.skip{
					color: $dark-gray;
					background: transparent;
					box-shadow: none;
				}
			}

			.modal-body{
				padding: 15px 35px;

				.intro-slider{
					visibility: hidden;
					// display: none;
					max-height: 0;
					.contain{
						text-align: center;
						.title{
							h2{
								font-weight: 800;
							}
						}
						.subhead{
						}
						.image{
							display: grid;
    						justify-content: center;
							min-width:100%;
							img{
								width: 320px;
								object-position: center;
								object-fit: cover;
							}
						}
					}
					.slick-dots {
						// bottom: -50px;
						li {
							margin: 0;
							button {
								margin: 0;
								width: 10px;
								height: 10px;
								&:before {
									margin: 0;
									color: $dark-gray;
									opacity: 1;
									font-size: 40px;
								}
							}
							&.slick-active {
								button {
									&:before {
										color: $teal;
									}
								}
							}
						}
				
					}
				}
				.slick-loaded{
					// display:block;
					visibility: visible;
					max-height:unset;
				}
				.slider-nav{
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					.prev-btn, .next-btn, .close-btn{
						font-weight: 800;
						background: transparent;
						border: none;
						outline: none;
						color: $teal;
					}

					.prev-btn{
						justify-self: flex-start;
					}
					.next-btn{
						justify-self: flex-end;
						transition: all 2.0s ease;
						max-height: 300px;
						opacity: 1;
						overflow: visible;
					}
					.close-btn{
						transition: all 0.3s ease;
						max-height: 0;
						opacity: 0;
						overflow: hidden;
						position: absolute;
						padding-right: 35px;
						right: 0;
					}
					
					.last-slide{
						transition: all 0.3s ease;
						max-height: 300px;
						overflow:visible;
						opacity: 1;
					}

					.slick-disabled{
						transition: all 0.3s ease;
						max-height: 0;
						opacity: 0;
						overflow: hidden;
					}

				}
			}
		
		}
	}
}

.badge-modal{
	.modal-dialog{
		.modal-content{
			.modal-body{
				text-align: center;
				.image{
					justify-self: center;
					margin-bottom: 10px;
				}
				h2{
					margin-top: 0 !important;
					font-family: 'latobold';
				}
				.btn-badge{
					color: $teal;
					font-weight: bold;
				}
				.information{
					margin-bottom: 20px;
				}
			}
		}
	}
}

#introModal,
#introHelpModal{
	.modal-dialog{
		.modal-content{
			.modal-body{
				.load-display{
					display: grid;
					justify-content: center;
					.loader {
						border: 16px solid #f3f3f3;
						border-radius: 50%;
						border-top: 16px solid #3498db;
						width: 120px;
						height: 120px;
						-webkit-animation: spin 2s linear infinite; /* Safari */
						animation: spin 2s linear infinite;
					}
					
					@keyframes spin {
					0% { transform: rotate(0deg); }
					100% { transform: rotate(360deg); }
					}
				}
			}
		}
	}
	
}

#storyForm {
	padding-right: 0px !important;
	.modal-dialog {
		height: 100%;
		.modal-content {
			padding: 30px;
			background-image: url('../img/app/waves.jpg');
			background-size: auto;
			background-position: center;
			overflow: auto;
			margin: auto;
			width: 100%;
			.center{
				display: flex;
				justify-content: center;
			}
			.filter-title {
				text-transform: unset;
			}
			.unset-margin{
				margin-bottom: 0px;
			}
			.form-container{
				.form-group{
					label{
						width: 100%;
					}
					.story-grid{
						display: grid;
						grid-template-columns: repeat(2, minmax(100px, 1fr));
						grid-gap: 10px;
						
						input[type="radio"] {
							display: none;
				
							&:checked {
								~.input-unchecked {
									display: none;
								}
								~.input-checked {
									display: inline-block;
									color: white;
								}
							}
						}
						.icon-grid{
							display: flex;
							flex-direction: column;
							background-color: $background-gray;
							border-radius: 20px;
							padding: 20px 0px;
							cursor: pointer;
							i{
								font-size: 42px;
								text-align: center;
							}
							span{
								margin-top: 5px;
								text-align: center;
							}
							.input-unchecked {
								display: inline-block;
							}
							.input-checked {
								display: none;
							}
						}
					}
				}
				.title, .article-text-area, .quote-text-area, .tags, .banner,
				.language, .share-article, .share-quote, .share-video, .share-photos, .share-first-part, .button-change,
				.video-link, .photos-area, .rollout{
					.top{
						font-size: 42px;
					}
					&.hideContent{
						display: none;
						max-height: 0;
						opacity: 0;
					}
				}
				.photos-area{
					display: grid;
					grid-gap: 30px;
					.share-story-image {
						cursor: pointer;
						display: inline-block;
						button {
							width: 100%;
    						height: 70px;
							background: #fff;
							border-radius: 5px;
							font-size: 24px;
							padding: 0 8px;
							position: relative;
							cursor: pointer;
							input {
								position: absolute;
								top: 0;
								left: 0;
								opacity: 0;
								width: 100%;
								height: 100%;
								cursor: pointer;
								font-size: 0;
							}
						}
					}
					.photos-row{
						height:120px;
						display:grid;
						grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
						justify-items: center;
						align-items: center;
						overflow-y: scroll;
						img {
							max-width: 120px;
						}
						&.hideContent{
							display: none;
							max-height: 0;
							opacity: 0;
						}
					}
				}
				.title{
					.article-photo-title, .video-title{
						&.hideContent{
							display: none;
							max-height: 0;
							opacity: 0;
						}
					}
				}
				.language{
					text-align: unset;
				}
			}
			.save-publish{
				max-width: unset;
				&.hideContent{
					display: none;
					max-height: 0;
					opacity: 0;
				}
			}
		}
	}
	.story-header {
		background: url('../img/app/waves.jpg');
		background-size: auto;
		background-repeat: repeat;
		padding: 20px;
		.close {
			top: 20px;
		}
	}
	.story-body {
		padding: 20px;
		background: #F0F0F0;
		.content-card {
			border: 1px solid #DEDEDF;
			border-radius: 4px;
			margin-bottom: 15px;
			&.dashed {
				border-radius: 10px;
				border: 1px dashed #707070;
			}
			&.text {
				padding: 20px;
				color: $black-4;
			}
			&.image {
				padding: 0;
				height: 215px;
				width: 100%;
				overflow: hidden;
				img {
					object-fit: cover;
					height: 100%;
					width: 100%;

				}
			}
			&.asset {
				padding: 30px 10px;
				.btn-add-asset {
					background: #E0E1E2;
					color: rgba(0,0,0,0.6);
				}
				.action-grid {
					display: grid;
					grid-template-columns: repeat(auto-fit, minmax(65px, 1fr));
					grid-gap: 10px;
					margin-top: 15px;
				}
			}
			.section-title {
				font-family: $latobold;
				color: black;
			}
		}
	}
}
.text-center {
	text-align: center;
}
.home-page {
	.grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-gap: 50px;
		margin: 50px 0;
	}
	.banner {
		height: 460px;
		width: 100%;
		display: grid;
		color: white;
		position: relative;
		background-repeat: no-repeat!important;
		background-size: cover!important;
		background-position: center center!important;
		.orange-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.85);
			background: -moz-linear-gradient(top, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.85)), color-stop(100%, rgba(0,0,0,0)));
			background: -webkit-linear-gradient(top, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
			background: -o-linear-gradient(top, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
			background: -ms-linear-gradient(top, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7d1c', endColorstr='#ff7d1c', GradientType=0 );
		}
		.btn {
			min-width: unset;
		}
		.text {
			padding: 30px 0;
			position: relative;
		}
		.small-text {
			font-family: $latomedium;
			font-size: 20px;
		}
		.big-text {
			font-family: $latoblack;
			font-size: 30px;
			line-height: 1.1;
			margin-bottom: 5px;
		}
		&.top {
			align-items: flex-start;
		}
		&.bottom {
			align-items: flex-end;
		}
		&.middle {
			align-items: center;
		}
	}
	.slick-dots {
		li {
			margin: 0;
			button {
				margin: 0;
				width: 10px;
				height: 10px;
				&:before {
					margin: 0;
					color: $beige;
					opacity: 1;
					font-size: 40px;
				}
			}
			&.slick-active {
				button {
					&:before {
						color: $pink;
					}
				}
			}
		}

	}
	.btn {
		min-width: 250px;
	}
	.section-title {
		font-size: 28px;
		font-family: $latobold;
		color: black;
	}
	.intro {
		font-size: 18px;
		font-family: $latobold;
	}
	.impact-section {
		padding: 25px 0;
		background: url('../img/app/waves.jpg');
		background-size: auto;
		background-repeat: repeat;
		margin-top: 50px;
		.dropdown {
			.btn {
				background: transparent;
			}
			&.open {
				.btn {
					background: transparent;
				}
			}
		}
	}
	.founder-section, .sponsors-section {
		img {
			max-width: 100%;
			max-height: 80px;
		}
		a {
			margin-top: 20px;
			max-width: 50%;
		}

		.sponsor-grid {
			display: grid;
			margin-top: 15px;
			grid-gap: 15px;
			@media (max-width: 475px){
				img {
					margin: auto;
				}
				a {
					margin: 20px auto 0;
				}
			}
		}
	}
	.sponsors-section{
		justify-self: center;
	}
	.activity-grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
		grid-gap: 5px;
		margin: 20px auto;
		.activity {
			.icon {
				margin: 0 auto 10px;
				background-size: cover!important;
				background-position: center center!important;
				display: grid;
				align-items: center;
				justify-items: center;
				height: 70px;
				width: 70px;
				border-radius: 10px;
				img {
					max-height: 50px;
				}
			}
		}
	}
	.partner-grid {
		display: grid;
		grid-auto-flow: column;
		grid-gap: 10px;
		align-items: center;
		justify-content: center;
		margin: 15px auto;
		img {
			max-height: 60px;
		}
	}
	.impact-grid {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		align-items: center;
		justify-items: center;
		max-width: 600px;
		margin: auto;
		@media (max-width: 600px){
			grid-template-columns: 1fr 1fr;
		}
		.impact {
			text-align: center;
			.number {
				color: $teal;
				font-size: 56px;
				font-family: $latoblack;
			}
			.title {
				font-family: $latobold;
			}
		}
	}
	.project-dropdown {
		text-align: center;
	}
	#map {
		height: 70vh;
		width: 100%;
	}
	.diagonal-bg {
		background: url('../img/app/waves.jpg');
		background-size: auto;
		background-repeat: repeat;
		padding: 30px 0;
	}
	@media(max-width: 320px) {
		.grid {
			grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
			align-items: center;
		}
	}
}

.founder-grid {
	display: grid;
	grid-template-columns: 90px auto;
	grid-gap: 15px;
	margin-top: 15px;
	img {
		max-width: 100%;
		max-height: 80px;
	}
	a{
		max-width: unset;
	}
	a:hover{
		opacity: .7;
	}
	.text {
		text-align: center;
		grid-column: 1/ span 2;
		margin-top: -6px;
		small {
			color: $black-4;
			font-size: 11px;
			text-transform: uppercase;
			font-family: $latomedium;
			margin-bottom: 5px;
		}
	}
}
.home-page, .rollouts-page, .people-page {
	.dropdown {
		margin-top: 10px;
		.btn {
			min-width: 120px;
			border: none;
			outline: none;
			background: white;
			color: $pink;
			box-shadow: none;
			.image {
				width: 35px;
				height: 35px;
				overflow: hidden;
				border-radius: 100%;
				display: inline-block;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		
		.country-dropdown {
			padding: 0;
			min-width: 50px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				margin-right: 5px;
			}
			
		}
		&.open {
			.btn {
				background: white;
				color: $pink;
				box-shadow: none;
			}
		}
		.dropdown-menu {
			padding: 15px;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
			border-radius: 8px;	
			background: rgba(255,255,255,0.9);
			width: 300px;
			&:before {
				content:"";
				position: absolute;
				left: 15px;
				top: -10px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 10px 10px 10px;
				border-color: transparent transparent rgba(255,255,255,0.9) transparent;
				z-index:9999;
			}
			&:after {
				content:"";
				position: absolute;
				left: 15px;
				top: -22px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 17px 17px 17px;
				border-color: transparent transparent rgba(255,255,255,0.9) transparent;
				z-index:9998;
			}
			li {
				border-bottom: 1px solid rgba(255, 61, 84, 0.3);
				&:last-child {
					border-bottom: none;
				}
				a {
					padding: 5px 0;
					color: $pink;
					font-family: $latomedium;
					font-size: 15px;

					&:hover, &.active {
						color: $teal;
						font-family: $latoheavy;
						background: none;
					}
				}
			}
		}

		span {
			font-size: 15px;
		}

		&.role {
			.btn {
				font-size: 15px;
			}
		}
	}
}

.small-banner {
	height: 210px;
	background-size:cover!important;
	background-position: center center!important;
	padding: 30px 0;
	
	.container {
		height: 100%;
		.grid {
			display: grid;
			align-items: flex-end;
			height: 100%;
		}
	}
	.text {
		color: black;
		.big-title {
			font-family: $latoheavy;
			font-size: 40px;
			line-height: 1;
		}
		.small-title {
			font-family: $latomedium;
			font-size: 24px;
			line-height: 1;
		}
	}
}
.card {
	background: white;
	border-radius: 25px;
	border: 1px solid #E6E6E7;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
	margin-bottom: 25px;
	display: block;
	.card-header {
		padding: 30px;
		.small-label {
			font-size: 13px;
			font-family: $latobold;
			color: $teal;
			text-transform: uppercase;
			margin-bottom: 15px;
		}
		.title {
			font-size: 28px;
			font-family: $latobold;
			color: black;
			line-height: 1;
		}
	}
	.resources {
		height: 120px;
		background-size: cover!important;
		background-repeat: no-repeat!important;
		border-bottom-left-radius: 25px;
		border-bottom-right-radius: 25px;
	}
}

// ACTIVITIES PAGE
.activities-page {
	font-size: 16px;
	margin-bottom: 50px;

	.activities {
		margin: 40px auto;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
		grid-gap: 40px;
		.activity {
			text-align: center;
			display: block;
			.icon {
				margin: 0 auto 10px;
				width: 165px;
				height: 165px;
				background-size: cover!important;
				background-position: center center!important;
				display: grid;
				align-items: center;
				justify-items: center;
				border-radius: 35px;
				img {
					max-height: 120px;
				}
			}
			.info {
				max-width: 165px;
				margin: auto;
				.name {
					font-size: 24px;
					font-family: $latoblack;
				}
				.description {
					font-size: 15px;
					font-family: $latoregular;
				}
			}
			
		}
	}
	.content {
		padding: 30px 0;
	}
	.section-title {
		padding: 0 15px;
		font-size: 18px;
		font-family: $latobold;
		margin-bottom: 20px;
	}
}
// END ACTIVITIES PAGE

// ROLLOUTS PAGE
.rollouts-page {
	margin-bottom: 50px;

	.dropdown {
		margin-bottom: 10px;
	}
	.search {
		max-width: 270px;
		margin: 50px auto 0;
		.input-group {
			border: 2px solid $pink;
			border-radius: 35px;
			.form-control {
				border-radius: 35px;
				border: none;
				outline: none;
				box-shadow: none;
				margin: 2px 0 2px 5px;
			}
			::-webkit-input-placeholder { /* Edge */
				font-family: $latomedium;
				color: $black-4;
			}
			
			:-ms-input-placeholder { /* Internet Explorer 10-11 */
				font-family: $latomedium;
				color: $black-4;
			}
			
			::placeholder {
				font-family: $latomedium;
				color: $black-4;
			}
			i {
				color: #9A9A9A;
			}
		}
	}
	.options{
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		.role{
			justify-self: flex-end;
			.btn{
				border-color: transparent;
				color: #FF3D54;
			}
		}
	}
}
.rollout-cards {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 30px;
	margin-bottom: 10px;
	@media(min-width: $tablet-breakpoint) {
		grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
	}
	.card {
		margin-bottom: 0;
		height: 350px;
		background-size: cover!important;
		background-repeat: no-repeat!important;
		.grid, .card-grid {
			border-radius: 25px;
			padding: 30px;
			display: grid;
			height: 100%;
			background: rgba(165,165,165,0);
			background: -moz-linear-gradient(top, rgba(165,165,165,0) 0%, rgba(85,85,85,0.8) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(165,165,165,0)), color-stop(100%, rgba(85,85,85,0.8)));
			background: -webkit-linear-gradient(top, rgba(165,165,165,0) 0%, rgba(85,85,85,0.8) 100%);
			background: -o-linear-gradient(top, rgba(165,165,165,0) 0%, rgba(85,85,85,0.8) 100%);
			background: -ms-linear-gradient(top, rgba(165,165,165,0) 0%, rgba(85,85,85,0.8) 100%);
			background: linear-gradient(to bottom, rgba(165,165,165,0) 0%, rgba(85,85,85,0.8) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a5a5a5', endColorstr='#555555', GradientType=0 );
		}
		.flag-grid {
			justify-self: flex-start;
			.flag {
				height: 45px;
				width: 45px;
				overflow: hidden;
				border-radius: 100%;
				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
		.text {
			align-self: flex-end;
			.name {
				font-size: 35px;
				color: white;
				font-family: $latoblack;
				line-height: 1.1;
			}
			.location {
				color: $teal;
				font-size: 15px;
				font-family: $latobold;
			}
			
		}
	}
	@media (max-width: 320px) {
		grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	}
}
// END ROLLOUTS PAGE

.rollout-view {
	margin-bottom: 50px;
	.info-section {
		.nav-tabs {
			li {
				width: 32.5%;
				&:first-child {
					width: 35%;
				}
			}
		}
	}
	.banner {
		position: relative;
		height: 91vh;
		background-repeat: no-repeat!important;
		background-size: cover!important;
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			background: #3C3C3C;
			color: white;
		}
		.grid {
			padding: 30px;
			display: grid;
			height: 100%;
			background: rgba(0,0,0,0);
			background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(60,60,60,0.7) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(60,60,60,0.7)));
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(60,60,60,0.7) 100%);
			background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(60,60,60,0.7) 100%);
			background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(60,60,60,0.7) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(60,60,60,0.7) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#3c3c3c', GradientType=0 );
		}
		.flag-grid {
			justify-self: flex-start;
			.flag {
				height: 60px;
				width: 60px;
				overflow: hidden;
				border-radius: 100%;
				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
		.text {
			align-self: flex-end;
			.name {
				font-size: 35px;
				color: white;
				font-family: $latoblack;
				line-height: 1.1;
			}
			.location {
				color: $teal;
				font-size: 15px;
				font-family: $latobold;
			}
			.arrow {
				color: white;
				text-align: center;
				margin-top: 10px;
				i {
					font-size: 25px;
				}
			}
		}
	}
	.members-section {
		background: url('../img/app/waves.jpg');
		background-repeat: repeat;
		background-size: auto;
		padding: 50px 0;
		.section-title {
			font-family: $latobold;
			font-size: 13px;
			text-transform: uppercase;
		}
		.members {
			margin: 10px 0;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
			.member-grid {
				display: grid;
				align-items: center;
				grid-gap: 15px;
				grid-template-columns: 56px 1fr;
				margin-bottom: 10px;
				.image {
					height: 56px;
					width: 56px;
					overflow: hidden;
					border-radius: 5px;
					&.avatar {
						padding: 10px 0;
						color: #f5f5f5;
						background: #DADADB;
						text-align: center;
						svg {
							max-width: 95%;
						}
					}
					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}
				.details {
					.name {
						font-size: 18px;
						font-family: $latobold;
						text-transform: capitalize;
					}
					.position {
						color: $black-7;
						font-size: 13px;
						font-family: $latoregular;
						text-transform: capitalize;
					}
				}
			}
		}
	}
	.video {
		height: 400px;
		background: #BCBEC0;
		margin-top: 10px;
		iframe {
			height: 100%;
			width: 100%;
		}
	}
	.media-grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		align-items: center;
		justify-items: center;
		grid-gap: 10px;
		margin-top: 20px;
		margin-bottom: 20px;
		.asset {
			overflow: hidden;
			img {
				max-width: 100%;
			}
		}
	}
	
	@media(min-width: $tablet-breakpoint) {
		.banner {
			height: 70vh;
		}
	}
}

.info-section {
	margin-top: 50px;
	.line {
		border-bottom: 2px solid #E2E2E3;
	}
	.nav-tabs {
		border-bottom: 2px solid #E2E2E3;
		margin: 0 auto -2px;
		li {
			text-align: center;
			margin-bottom: -2px;
			width: 33.33%;
			a {
				color: $pink;
				font-family: $latomedium;
				font-size: 13px;
				margin: 0;
				padding: 10px;
				&:hover {
					background: white;
				}
			}	
			&.active, &:hover {
				a {
					color: $teal;
					font-family: $latobold;
					border: none;
					border-bottom: 2px solid $teal;
				}
			}
		}
		@media(min-width: $tablet-breakpoint) {
			max-width: 60%;
		}
		@media(min-width: $big-tablet-breakpoint) {
			max-width: 65%;
			li {
				a {
					font-size: 25px;
				}
			}
		}
	}
	.tab-content {
		padding: 20px 0 0;
		min-height: 300px;
		.panel-title {
			font-size: 18px;
			font-family: $latobold;
		}
		.image {
			height: 288px;
			width: 100%;
			overflow: hidden;
			margin-bottom: 50px;
			img {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}
	}
	.description {
		font-size: 16px;
		margin: 25px 0 50px;
		img {
			max-width: 100%;
		}

		figure {
			text-align: center;
		}
	}
	.info-grid {
		.info {
			border-bottom: 1px solid #E2E2E3;
			padding: 15px;
			display: grid;
			grid-template-columns: 75px 1fr;
			grid-gap: 20px;
			&:last-child {
				border-bottom: none;
			}
			.info-label {
				font-family: $latoheavy;
			}
			.info-data {
				color: $teal;
			}
		}
	}
	.rollout-grid{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
		.rollout-progress{
			.rollout-progress-bar{
				margin: 15px 0;
				height: 28px;
				width: 100%;
				background: #CCCCCC;
				.progress-1{
					background: $teal;
					height: 28px;
					width: 35%;
				}
				.progress-2{
					background: $teal;
					height: 28px;
					width: 70%;
				}
				.progress-3{
					background: $teal;
					height: 28px;
					width: 100%;
				}
			}
			.checklist{
				font-family: 'latoregular';
				font-size: 22px;
				color: $black;
				ul{
					list-style-position: inside;
					list-style: none;
					padding: 0;
					li{
						display: grid;
						grid-template-columns: 40px 1fr;
						align-items: center;
						margin-bottom: 10px;
					}
					i{
						color: #CCCCCC;
						padding-right: 10px;
					}
					.check{
						color: $teal;
					}
				}
			}
		}
		@media (max-width: $tablet-breakpoint){
			grid-template-columns: 1fr;
		}
	}
	
}

.story-cards {
	margin: 20px 0;
	// display: grid;
	// grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	// display: grid;
	// grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	// grid-gap: 20px;
	// @media(max-width: 320px) {
	// 	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	// }
	// @media(min-width: $tablet-breakpoint) {
	// 	grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
	// }
	.card {
		display: grid;
		margin-bottom: 0;
		transition: .3s;
		.card-preview{
			max-height: 180px;
			width: 100%;
			overflow: hidden;
			padding: 0px 20px;
			align-self: end;
			font-size: 30px;
		}
		.quote{
			font-size: 24px;
		}
		.article{
			font-size: 16px;
		}
		.card-image {
			height: 180px;
			max-height: 200px;
			overflow: hidden;
			align-self: flex-end;
			&.no-image {
				background: $blue1;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.card-details {
			padding: 20px;
			align-self: end;
			.detail-grid {
				display: grid;
				grid-template-columns: 1fr 1fr;
				color: $black-4;
				font-size: 13px;
				margin-bottom: 10px;
				.story-type {
					text-transform: uppercase;
					&.draft {
						span {
							background: $orange1;
							padding: 0 7px;
							color: white;
							border-radius: 3px;
						}
					}
				}
				.date {
					justify-self: flex-end;
				}
			}
			.profile-grid{
				display: grid;
				grid-template-columns: 1fr 3fr 1fr;
				grid-gap: 10px;

				.image {
					width: 60px;
					height: 60px;
					overflow: hidden;
					border-radius: 20px;
					margin-bottom: 0;
					&.avatar {
						padding: 10px 0;
						color: #f5f5f5;
						background: #DADADB;
						text-align: center;
						svg {
							max-width: 95%;
						}
					}
					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}
				.author-details{
					display: flex;
					flex-direction: column;
					align-self: center;
					.author {
						font-size: 15px;
						font-weight: 800;
						font-family: $latomedium;
						text-transform: capitalize;
					}
				}
				.story-type{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					i{
						color: $teal;
						font-size: 30px;
					}
				}
			}
			.draft-status{
				margin-top: 20px;
				font-style: italic;
				color: red;
				font-weight: 800;
			}
			.title {
				font-size: 32px;
				font-family: $latoblack;
				line-height: 1.1;
				margin-top: 14px;
			}
			.video-photo {
				font-family: $latoregular;
			}
			.action-grid {
				display: grid;
				grid-template-columns: 50px 50px 1fr;
				grid-gap: 10px;
				justify-content: flex-start;
				font-size: 13px;
				.heart, .comments {
					.number {
						font-family: $latobold;
						margin-left: 2px;
					}
				}
				.heart {
					color: $pink;
				}
				.comments {
					color: $orange2;
				}
				.view-button{
					font-family: $latoblack;
					color: $pink;
					justify-self: end;
				}
			}
			// @media screen and (min-width: $laptop-breakpoint) {
			// 	margin-right: 25px;
			// }
		}
		.profile{
			align-self: start;
		}
		&:hover {
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36);
		}
	}

	.story-card-holder {
		width: 33%;

		.card {
			max-width: 350px;
			margin: 0 auto 20px;
		}

		@media screen and (min-width: $laptop-breakpoint) {
			.card{
				margin-right: 30px !important;
			}
		}
		@media screen and (max-width: $laptop-breakpoint) {
			.card {
				margin: 0 auto 20px !important;
			}
			width: 50%;
		}

		@media screen and (max-width: 991px) {
			width: 100%;
		}

	}

	@media screen and (min-width: $laptop-breakpoint) {
		.dashboard-story{
			width: 48%;
		}
	}

	.card {
		margin-bottom: 20px;
		.card-header {
			padding: 20px 15px 0;
			position: relative;
			display: grid;
			grid-template-columns: 50px 1fr 30px;
			align-items: center;

			.img-container {
				color: #44B3C4;
				width: 50px;
				height: 50px;
				overflow: hidden;

				img {
					max-width: 100%;
				}
			}

			.type-img {
				color: #44B3C4;
				font-size: 28px;
				position: absolute;
				line-height: 1;
				top: 20px;
				right: 15px;
				
				img {
					max-width: 30px;
				}
			}

			.author {
				padding: 0px 20px;

				.name {
					font-family: $latobold;
					font-size: 18px;
				}

				.location {
					font-size: 13px;
					font-family: $latoregular;
					color: #B5B5B5;
				}
			}
		}

		.card-content {
			padding: 20px 15px 20px;
		}
		
		.card-footer {
			padding: 20px 15px 20px;

			.action-grid {
				display: grid;
				grid-template-columns: 50px 50px 1fr;
				grid-gap: 10px;
				justify-content: flex-start;
				font-size: 13px;
				.heart, .comments {
					.number {
						font-family: $latobold;
						margin-left: 2px;
					}
				}
				.heart {
					color: $pink;
				}
				.comments {
					color: $orange2;
				}
				.view-button{
					font-family: $latoblack;
					color: $pink;
					justify-self: end;
				}
			}
		}
	}

	@media screen and (max-width: $laptop-breakpoint) {
		// grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
		

		.story-card-holder {

			.card {
				max-width: 400px;
			}
		}
	}

	@media screen and (max-width: 991px) {
		// grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));

		.story-card-holder {
			.card {
				max-width: 500px;
			}
		}

		
	}
	
	
	@media screen and (max-width:500px) {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}

	@media screen and (max-width: 375px) {
		grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

		.story-card-holder {
			.card {
				max-width: 280px;
			}
		}
	}

}

.dashboard-page{
	padding: 50px 0;
	.dashboard-banner{
		display: flex;
		flex-direction: column;
		align-items: center;
		.name{
			h1{
				margin: 50px 0;
				font-weight: bold;
			}
		}
	}

	.dashboard-grid{
		display: grid;
		grid-template-columns: 300px 1fr;
		grid-gap: 50px;
		h4{
			margin-bottom: 20px;
			font-weight: bold;
		}
		.actions{
			display: flex;
			flex-direction: column;
			.quick-actions{
				margin-bottom: 30px;
				.quick-action-grid{
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-gap: 30px;
					.action-card{
						display: grid;
						justify-content: center;
						padding: 20px 10px;
						background: white;
						border-radius: 25px;
						border: 1px solid #E6E6E7;
						box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
						grid-gap: 10px;
						outline: none;
						.image{
							overflow: hidden;
							height: 100px;
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
						.title{
							text-align: center;
							color: $pink;
							font-weight: bold;
						}
					}
				}
			}

			.notifications{
				font-family: $latomedium;
				.notification-container{
					overflow-y: auto;
					a{
						color: $teal;
					}
					.previous-notifications{
						&.hideContent{
							display: none;
							max-height: 0;
							opacity: 0;
						}
					}
				}
				.previous-button, .hide-button{
					padding-left: 0 !important;
					cursor: pointer;
					&.hideContent{
						display: none;
						max-height: 0;
						opacity: 0;
					}
				}
			}
		}

		.rollouts-stories{
			.story-cards {
				// grid-gap: 20px;
				// grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
				@media(max-width: 320px) {
					grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
					// grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
					.story-card-holder {
						// width: 240px;
						width: 50%;
					}
				}
				@media screen and (max-width: $laptop-breakpoint) {
					.story-card-holder {
						.card {
							max-width: 250px;
						}
					}
				}

				@media screen and (max-width: 991px) {
					.story-card-holder {
						width: 100%;
						.card {
							max-width: 400px;
						}
					}
				}
				
				// @media(max-width: 320px) {
				// 	// grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
				// 	.story-card-holder {
				// 		// width: 280px;
				// 	}
				// }
				// @media(min-width: $tablet-breakpoint) {
				// 	// grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
				// 	.story-card-holder {
				// 		// width: 330px;
				// 	}
				// }
			}
			.explore{
				display: flex;
				justify-content: flex-end;
				margin: 30px 0;
			}
		}

		@media(max-width: 720px) {
			grid-template-columns: 230px 1fr;
			grid-gap: 20px;
			.actions{
				.quick-actions{
					.quick-action-grid{
						grid-gap: 20px;
					}
				}
			}
		}
		@media(max-width: 600px) {
			grid-template-columns: 1fr;
			.rollouts-stories{
				.explore{
					justify-content: center;
				}
			}
		}
		@media(max-width: 450px) {
			.actions{
				.quick-actions{
					.quick-action-grid{
						justify-content: center;
					}
				}
			}
		}
	}
}

footer {
	border-top: 2px solid $background-gray;
	.grid {
		padding: 50px 0;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-gap: 30px;
		align-items: flex-start;
	}
	.founder-grid {
		grid-template-columns: auto auto;
		margin-top: 0;
		justify-content: center;
    	align-self: center;
		img{
			max-width: unset;
			max-height: 50px;
		}
	}
	.logo {
		text-align: center;
		img {
			max-height: 75px;
		}
	}
	.social-section{
		color: #333333;
		background: $background-gray;
		padding: 20px 0;
		.social {
			display: grid;
			grid-template-columns: 180px 30px 30px;
			grid-gap: 15px;
			align-items: center;
			justify-content: center;
			font-family: $latobold;
			font-size: 13px;
			svg {
				color: white;
				&.fa-facebook {
					&:hover {
						color: #3b5998;
					}
				}
				&.fa-instagram {
					&:hover {
						color: #C13584;
					}
				}
			}
		}
	}
	.newsletter {
		// margin-bottom: 20px;
		.label {
			font-family: $latobold;
			font-size: 13px;
			color: #333333;
			padding: 0;
		}
		.input-group {
			margin-top: 6px;
		}
		.form-control {
			border: 1px solid #DEDEDF;
			border-radius: 4px!important;
			height: 38px;
		}
		.input-group-btn {
			padding-left: 8px;
		}
	}
	@media (max-width: 320px) {
		.grid {
			grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		}
		.social {
			grid-template-columns: 160px 30px 30px;
			img {
				max-width: 30px;
			}
		}
	}
	@media(min-width: $tablet-breakpoint) {
		.founder-grid{
			align-items: flex-start;
		}
	}
	.help{
		// margin: 25px;
		display: flex;
		justify-content: center;
		align-self: center;
		a{
			font-weight: bold;
			color: $pink;
			margin-right: 15px;
		}
		.btn-help{
			font-weight: 800;
			background: transparent;
			box-shadow: none;
			color: $pink;
			border:none;
			outline:none;
		}
	}
}

.people-page {
	margin-bottom: 50px;

	.content {
		min-height: 500px;
	}
	.filter-grid {
		margin-top: 20px;
		.role-filter, .country {
			color: $pink;
			.btn {
				border: none;
				outline: none;
				background: white;
				color: $pink;
				box-shadow: none;

				img {
					max-width: 35px;
				}
			}
		}
		.country {
			display: flex;
			display: -webkit-flex;
			align-items: center;
			span {
				margin-right: 8px;
			}
			i {
				margin-left: 8px;
			}
			.country-dropdown {
				display: flex;
				display: -webkit-flex;
				align-items: center;
			}
		}
	}
	.people-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(160px, 160px));
		grid-gap: 15px;
		margin-top: 40px;
		justify-content: center;
		.member {
			.image {
				width: 160px;
				height: 170px;
				overflow: hidden;
				border-radius: 20px;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
				margin: 0 auto 10px;
				position: relative;
				&.avatar {
					padding: 20px 0;
					text-align: center;
					color: #f5f5f5;
					background: #DADADB;
					
					svg {
						max-width: 95%;
					}
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				.flag {
					position: absolute;
					top: 5px;
					left: 5px;
					width: 45px;
					height: 45px;
					overflow: hidden;
					border-radius: 100%;
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
			.details {
				text-align: center;
				margin-bottom: 10px;
				.name {
					font-size: 18px;
					font-family: $latobold;
					text-transform: capitalize;
				}
				
				.location {
					font-size: 13px;
					color: $black-4;
				}
			}
		}
	}
}
.people-view, .profile-page {
	margin-bottom: 50px;

	.tab-content {
		padding: 25px 0;
	}
	.banner {
		height: 360px;
		position: relative;
		background-size: cover!important;
		background-repeat: no-repeat!important;
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			background: #3C3C3C;
			color: white;
		}
	}
	.member-info {
		padding: 20px;
		.name {
			font-size: 28px;
			font-family: $latobold;
			text-transform: capitalize;
		}
		.role {
			font-size: 13px;
			text-transform:uppercase;
			font-family: $latobold;
			&.manager {
				color: $teal;
			}
			&.facilitator {
				color: $orange2;
			}
			&.technical {
				color: $olive;
			}
		}
		.location {
			font-size: 13px;
			color: $black-4;
		}
		.description {
			margin-top: 20px;
			font-size: 13px;
		}
	}
	.nav-tabs {
		li {
			width: 50%;
			&:first-child, &:last-child  {
				width: 25%;
			}
		}
	}
	.social-media {
		background: rgba(218, 218, 219, 0.4);
		padding: 10px 0;
		text-align: center;
		margin-bottom: 20px;
		span {
			padding: 0 10px;
			img {
				max-width: 20px;
			}
		}
	}

	.badges {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 20px;
		.badge-profile{
			display:grid;
			grid-template-columns: 1fr 1fr;
			.badge-image{
				height: 100px;
				width: 100px;
				img{
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
			.badge-info{
				align-self: center;
				.name{
					font-family: 'latobold';
				}
				.level{
					font-family: 'latoregular';
				}
			}
		}
		@media (max-width: 580px){
			grid-template-columns: 1fr 1fr;
		}
		@media (max-width: 410px){
			grid-template-columns: 1fr;
			.badge-profile{
				grid-gap: 10px;
				.badge-image{
					margin-left: auto;
				}
			}
		}
		.cert {
			height: 60px;
			width: 60px;
			border-radius: 100%;
			border: 5px solid $pink;
			&.pink {
				border-color: $pink;
			}
			&.teal {
				border-color: $teal;
			}
			&.orange {
				border-color: $orange2;
			}
			&.olive {
				border-color: $olive;
			}
			&.beige {
				border-color: $beige;
			}
		}
	}
}

.activity-view {
	margin-bottom: 50px;
	.banner {
		position: relative;
		height: 360px;
		background-repeat: no-repeat!important;
		background-size: cover!important;
		.container {
			height: 100%;
		}
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			background: rgba(60, 60, 60, 0.6);
			color: white;
			opacity: 1;
		}
		.grid {
			padding: 30px 0;
			display: grid;
			height: 100%;
		}
		.flag-grid {
			justify-self: flex-start;
			.flag {
				height: 60px;
				width: 60px;
				overflow: hidden;
				border-radius: 100%;
				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
		.text {
			align-self: flex-end;
			color: white;
			.small-title {
				font-size: 24px;
				font-family: $latomedium;
				line-height: 1.1;
			}
			.big-title {
				font-size: 40px;
				font-family: $latoblack;
				line-height: 1.1;
			}
		}
	}
	.nav-tabs {
		margin: 20px auto -2px;
		li {
			width: 27%;
			&:last-child {
				width: 46%;
			}
			a {
				padding: 10px 5px;
			}
		}
	}
	.tab-content {
		.mechanics {
			margin: 50px auto 0;
			text-align: center;
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
			grid-gap: 20px;
			align-items: center;
			max-width: 350px;
			.icon-pink {
				background: $pink;
				border-radius: 20px;
				width: 130px;
				height: 130px;
				margin: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				i {
					font-size: 60px;
					color: white;

				}
			}
			.image {
				margin-bottom: 5px;
				max-height: 190px;
			}
			img {
				max-width: 100%;
			}
			.text {
				color: $pink;
				text-transform: uppercase;
				font-size: 13px;
				line-height: 1;
				font-family: $latobold;
				margin-top: 10px;
			}
		}
		#summary {
			font-size: 16px;
			h4 {
				font-family: $latobold;
			}
			iframe {
				margin: 50px auto 0;
				width: 100%;
				// height: 300px;
				max-width: 800px;

				@media screen and (max-width: 500px) {
					height: 300;
				}
			}
			
		}

		#facilitation {

			.textarea-holder {
				// box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
				border: 1px solid #ccc;
				textarea {
					min-height: 110px;
					overflow: hidden;
					border-color: transparent;
					box-shadow: none;
					margin-bottom: 10px;
				}

				.img-container {
					display: none;
					margin: 15px;
					width: 200px;
					height: 200px;
					background: rgba(0,0,0,0.3);
					align-items: center;
					justify-content: center;
					position: relative;
					.delete {
						position: absolute;
						top: -10px;
						right: -10px;
						font-size: 18px;
						cursor: pointer;
					}
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
			}

			#addMedia {
				position: relative;

				input {
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;
					width: 100%;
					height: 100%;
					cursor: pointer;
					font-size: 0;
				}
			}
		}
	}
}
.story-view {
	margin-bottom: 50px;

	.close {
		position: relative;
		z-index: 2;
		margin: 10px;
		background: $black-4;
		color: white;
	}
	.banner {
		height: 280px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		&.no-image {
			background: $blue1;
		}
	}
	.story-info {
		padding: 50px 0;
		background: url('../img/app/waves.jpg');
		background-repeat: repeat;
		background-size: auto;
		.details-grid {
			font-size: 13px;
			color: $black;
			text-transform: uppercase;
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: center;
			.date {
				color: $black-7;
				justify-self: flex-start;
			}
		}
		.title-view{
			display: grid;
			grid-template-columns: 1fr 120px;
			.title {
				font-size: 22px;
				font-family: $latoblack;
				line-height: 1.1;
				margin-top: 14px;
			}
			.video-photo {
				font-family: $latoregular;
			}
			.publish-draft{
				justify-self: end;
				align-self: center;
			}
		}
		.published{
			grid-template-columns: 1fr;
		}
		.title {
			font-size: 24px;
			font-family: $latoblack;
			line-height: 1.1;
			margin: 10px 0;
		}
		.author-grid {
			display: grid;
			grid-template-columns: 56px 1fr;
			grid-gap: 15px;
			align-items: center;
			.image {
				overflow: hidden;
				height: 56px;
				width: 56px;
				border-radius: 5px;
				&.avatar {
					padding: 10px 0;
					color: #f5f5f5;
					background: #DADADB;
					text-align: center;
					svg {
						max-width: 95%;
					}
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.author {
				.name {
					font-size: 20px;
					font-family: $latobold;
					text-transform: capitalize;
				}
				.location {
					font-size: 14px;
					color: $black-7;
				}
			}
		}
	}
	.video-tag{
		width:100%;
		height:300px;
		text-align: center;
		margin: 100px 0;
	}
	.images{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap:20px;
		padding: 30px 0;
		.image-tag{
			img{
				width: 100%;
				max-height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}
		@media screen and (max-width: 660px) {
			grid-template-columns: 1fr;
			grid-gap:20px;
		}
	}
	.story {
		padding: 30px 0;
		font-size: 16px;
		b {
			font-family: $latobold;
		}
		img {
			max-width: 100%;
		}
	}
	.quote{
		padding: 30px 0;
		font-size: 36px;
		font-family: $latoregular;
	}
	.tags {
		padding: 20px 0;
		.title {
			font-size: 13px;
			font-weight: bold;
			color: $black-4;
			margin-bottom: 10px;
		}
		.tag-grid {
			.tag {
				border-radius: 5px;
				padding: 5px 10px;
				text-align: center;
				color: $black;
				background-color: $gray1;
				font-family: $latobold;
				margin-left: 10px;
				&:first-child{
					margin-left: 0px;
				}
			}
		}
		
	}
	.btn-group {
		margin-right: 30px;
		&.btn-gray-pill {
			button {
				border-color: $gray1;
				color: $black;
				i {
					padding-right: 10px;
				}
				&:first-child {
					background: $gray2;
				}
			}
		}
		@media screen and (max-width: 623px) {
			margin-right: 0;
		}
	}
	.cta {
		padding: 20px 0;
		.action-grid {
			display: grid;
			grid-template-columns: 260px 1fr;
			.btn-pill{
				display: flex;
			}
			.like{
				margin-top: 0;
			}
			.share {
				display: flex;
				align-items: flex-end;
				.title{
					color: $black-4;
					margin-right: 15px;
					align-self: center;
				}
				.media {
					margin-top: 0;
					svg {
						max-width: 30px;
						color: white;
						background: $black-6;
						padding: 3px;
						border-radius: 5px;
						&.fa-facebook, 
						&.fa-instagram{
							&:hover {
								color: $dark-gray;
							}
						}
					}
				}
			}

			@media screen and (max-width: 623px) {
				grid-template-columns: 1fr;
				.like{
					margin-top: 15px;
					margin-right: 20px;
				}
				.share{
					max-height: 30px;
					align-self: flex-end;
					.title{
						align-self: center;
					}
				}
			}

			@media screen and (max-width: 359px) {
				.like{
					margin-right: 5px;
				}
				.share{
					.title{
						margin-right: 5px;
					}
					.media{
						svg{
							max-width: 25px;
							max-height: 25px;
						}
					}
				}
			}
			@media screen and (max-width: 325px) {
				.like{
					margin-right: 15px;
					.auth-btn{
						padding: 9px 10px;
					}
				}
			}
		}
	}
	.comments {
		background: #F0F0F0;
		padding: 20px 0;
		margin-bottom: 30px;
		.close-container{
			display: flex;
			justify-content: flex-end;
			.close-comments {
				position: relative;
				z-index: 2;
				color: $black-4;
				padding: 0px;
				i{
					padding-left:10px;
				}
			}
		}
		.comments-container {
			margin-top: 10px;
		}
		.title {
			font-family: $latobold;
		}
		.comment-grid {
			background: white;
			padding: 10px;
			border-radius: 5px;
			.image {
				overflow: hidden;
				border-radius: 5px;
				&.avatar {
					padding: 10px 0;
					color: #f5f5f5;
					background: #DADADB;
					text-align: center;
					position: relative;
					svg {
						position: absolute;
						width: 100%;
						height: 100%;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
		#load-more {
			display: flex;
			justify-content: center;
			padding-bottom: 20px;
			.load-more {
				i {

				}
			}
		}

	}
	.padding-top{
		padding-top: 30px;
	}

	.close,
	.banner,
	.video-tag,
	.image-tag,
	.images,
	.story,
	.quote,
	.cta,
	.tags,
	.comments{
		&.hideContent{
			display: none;
			max-height: 0;
			opacity: 0;
		}
	}
}

.about-page {
	font-size: 16px;

	.video-grid{
		// display: grid;
		// grid-template-columns: 250px 1fr;
		// grid-gap: 50px;
		// margin-top: 50px;
		.desc{
			margin: 50px 0;
			font-size: 21px; 
			font-family: $latobold;
			text-align: center;
			color: $orange2;
		}
		.video {
			height: 350px;
			max-height: 350px;
			iframe {
				width: 100%;
				height: 100%;
			}
		}
		// @media (max-width: 776px){
		// 	grid-template-columns: 1fr;
		// }
	}
	
	img {
		max-width: 100%;
	}
	.content {
		padding: 50px 0;
		font-size: 16px;
	}
	.title {
		font-size: 24px; 
		font-family: $latobold;
		color: black;
	}
	.founded-section{
		display: grid;
		grid-template-columns: auto auto;
		align-items: center;
		margin-top: 0;
		justify-content: center;
		align-self: center;
		grid-gap: 30px;
		
		.founder-grid{
			grid-template-columns: auto auto auto;
			margin-top: 0;
			justify-content: center;
			align-items: center;
			grid-gap: 30px;
			.images{
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 30px;
				img{
					max-width: unset;
					max-height: 50px;
				}
			}
			@media (max-width: 496px){
				grid-template-columns: auto;
				.description{
					text-align: center;
				}
			}
		}
		@media (max-width: 991px){
			grid-template-columns: auto;
		}
	}
	.how-it-works{
		background: $gray1;
		padding: 40px 0;
		.title{
			text-align: center;
			margin-bottom: 80px;
		}
		.about-grid{
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 30px;
			margin-top: 30px;
    		margin-bottom: 50px;
			.about-card{
				display: grid;
				grid-template-rows: 420px 1fr;
				grid-gap: 20px;
				.background{
					background: white;
					border-radius: 25px;
					border: 1px solid $background-gray;
					padding: 30px 20px;
					box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
					.about-title{
						font-family: $latobold;
						font-size: 24px;
					}
				}
				&:nth-child(1){
					.background{
						.about-title{
							color: $pink;
						}
					}
				}
				&:nth-child(2){
					.background{
						.about-title{
							color: $orange2;
						}
					}
				}
				&:nth-child(3){
					.background{
						.about-title{
							color: $teal;
						}
					}
				}
				span{
					font-weight: bold;
				}
				.buttons{
					display: flex;
					flex-direction: column;
					button:not(:last-child){
						margin-bottom: 10px;
					}
				}
				@media (max-width: 1200px){
					grid-template-rows: 470px 1fr;
				}
			}
			@media (max-width: 991px){
				grid-template-columns: 1fr;
				grid-gap: 50px;
				.about-card{
					grid-template-rows: 1fr;
				}
			}
		}
		img{
			border-radius: 25px;
		}
	}
	.yellow {
		border: 10px solid rgba(255, 248, 112, 0.5);
		.btn {
			min-width: 250px;
		}
	}
	.blue {
		border: 10px solid $blue2;
		.btn {
			min-width: 200px;
		}
	}
	.orange {
		border: 10px solid $orange2;
		.btn {
			min-width: 200px;
		}
	}
	.yellow, .orange, .blue {
		padding: 20px;
		.container-20 {
			background: white
		}
		.btn {
			margin-bottom: 10px;
		}
	}
}

.pink-link {
	color: $pink;
	font-family: $latobold;
}

.pagination-links {
	text-align: center;
	.pagination {
		li {
			span, a {
				border-color: $pink;
				color: $pink;					
			}
			&.active, &:hover {
				span,a {
					background: $pink;
					border-color: $pink;
					color: white;
				}
			}
		}
		
	}
}

.stories-page {
	margin-bottom: 50px;
	
	.filter-grid {
		margin: 0 auto;
		.btn {
			outline: none;
		}
		.btn-default {
			color: $pink;
			border: none;
		}
	}
	.search {
		max-width: 270px;
		margin: 20px auto;
		.input-group {
			border: 2px solid $blue1;
			border-radius: 35px;
			.form-control {
				border-radius: 35px;
				border: none;
				outline: none;
				box-shadow: none;
				margin: 2px 0 2px 5px;
			}
			::-webkit-input-placeholder { /* Edge */
				font-family: $latomedium;
				color: $black-4;
			}
			
			:-ms-input-placeholder { /* Internet Explorer 10-11 */
				font-family: $latomedium;
				color: $black-4;
			}
			
			::placeholder {
				font-family: $latomedium;
				color: $black-4;
			}
			i {
				color: #9A9A9A;
			}
		}
	}
}

.signup-page {
	margin-bottom: 50px;
	.house-rules{
		margin: 50px auto 0;
		max-width: 600px;
		display: grid;
		grid-gap: 15px;
		transition: .2s;
		.house-rules-grid{
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 15px;
			h1{
				margin-top: 20px;
				margin-bottom: 50px;
				font-weight: bold;
			}
			h2{
				margin-top: 10px;
				margin-bottom: 50px;
				font-size: 20px;
			}
			h3{
				color: $teal;
				font-weight: bold;
			}
			.column-one{
				.image{
					display: flex;
					justify-content: center;
				}
			}
			.column-two{
				.paragraph{
					margin: 20px 0;
				}
			}
			a{
				color: $fb-blue;
				text-decoration: underline;
			}
			span{
				font-style: italic;
				font-weight: bold;
			}
		}
		.agreement{
			border-radius: 25px;
			max-width: 300px;
			background: #D8D8DB;
			padding: 20px;
			justify-self: center;
			.form-container{
				.btn-container{
					display: flex;
    				justify-content: center;
				}
				.checkbox input[type="checkbox"] {
					opacity: 0;
				}
				
				.checkbox label {
					position: relative;
					display: inline-block;
					font-weight: bold;
					
					/*16px width of fake checkbox + 6px distance between fake checkbox and text*/
					padding-left: 32px;
					margin-bottom: 20px;
				}
				
				.checkbox label::before,
				.checkbox label::after {
					position: absolute;
					content: "";
					
					/*Needed for the line-height to take effect*/
					display: inline-block;
				}
				
				/*Outer box of the fake checkbox*/
				.checkbox label::before{
					height: 20px;
					width: 20px;
					
					border: 1px solid gray;
					border-radius: 1px;
					left: 0px;
					
					/*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
					 *to vertically center it.
					 */
					top: 3px;
					background: white
				}
				
				/*Checkmark of the fake checkbox*/
				.checkbox label::after {
					height: 8px;
					width: 13px;
					border-left: 2px solid;
					border-bottom: 2px solid;
					
					transform: rotate(-45deg);
					
					left: 4px;
					top: 7px;
				}
				
				/*Hide the checkmark by default*/
				.checkbox input[type="checkbox"] + label::after {
					content: none;
				}
				
				/*Unhide on the checked state*/
				.checkbox input[type="checkbox"]:checked + label::after {
					content: "";
				}
				
				/*Adding focus styles on the outer-box of the fake checkbox*/
				.checkbox input[type="checkbox"]:focus + label::before {
					outline: rgb(59, 153, 252) auto 5px;
				}
			}
		}
		&.hideContent{
			display: none;
			max-height: 0;
			opacity: 0;
		}
	}
	.signup-card	{
		margin: 50px auto 0;
		border-radius: 35px;
		max-width: 300px;
		transition: .2s;
		&.hideContent{
			display: none;
			max-height: 0;
			opacity: 0;
		}
		.signup-header {
			border-top-left-radius: 35px;
			border-top-right-radius: 35px;
			border: 1px solid #D8D8DB;
			padding: 20px;
			.logo {
				text-align: center;
				img {
					max-height: 100px;
				}
			}
			.header {
				font-size: 28px;
				font-family: $latobold;
				text-align: center;
			}
		}
		.signup-body {
			padding: 25px;
			border: 1px solid #D8D8DB;
			border-top: none;
			background: #ececec;
			border-bottom-left-radius: 35px;
			border-bottom-right-radius: 35px;
			.form-group {
				margin-bottom: 20px;
				.form-control {
					background: white;
					border-radius: 35px;
					height: 42px;
					padding: 10px 20px;
					border: 1px solid #707070;
					&:focus { 
						box-shadow: none;
					}
				}
				.select2-container--default {
					.select2-selection--single {
						background: white;
						border-radius: 35px;
						height: 42px;
						padding: 0 20px;
						border: 1px solid #707070;
						outline: none;
						.select2-selection__rendered {
							line-height: 42px;
							padding-left: 0;
						}
						.select2-selection__arrow {
							height: 42px;
							right: 10px;
						}
					}
					
				}
			}
			.btn {
				width: 100%;
			}
		}
	}
}

.grid-profile {
	display: grid;
	grid-template-columns: 55px 1fr;
	grid-gap: 15px;
	align-items: center;
	max-width: 250px;
	margin: 0 auto;
	.image {
		width: 55px;
		height: 55px;
		overflow: hidden;
		border-radius: 5px;
		&.avatar {
			background: #DADADB;
			padding: 10px 0;
			text-align: center;
			svg {
				color: #f5f5f5;
				max-width: 95%;
			}
		}
		img {
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
		}
	}
	.name {
		font-size: 18px;
		font-family: $latobold;
		text-transform: capitalize;
	}
	.location {
		font-size: 13px;
		color: $black-4;
	}
}

.logout {
	color: $pink;
	font-size: 15px;
	text-transform: uppercase;
	font-family: $latobold;
	background: transparent;
	border: none;
	margin-top: 10px;
	outline: none;
}

.language {
	text-align: center;
	a {
		text-transform: uppercase;
		color: #999999;
		font-size: 16px;
		font-family: $latobold;
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}

		&:hover, &.active {
			color: $teal;
		}
	}
}
.profile-page, .resource-page {
	margin-bottom: 50px;
	.banner {
		height: 160px;
		background: url('../img/app/purple-bg.png');
		background-size: cover;
	}
	.nav-tabs {
		margin: 20px auto -2px;
		li {
			width: 27%;
			&:nth-child(2) {
				width: 46%;
			}
			a {
				padding: 10px 5px;
			}
		}
	}
	.info {
		padding: 20px;
		.name {
			font-size: 28px;
			font-family: $latobold;
			text-transform: capitalize;
		}
		.role {
			font-family: $latobold;
			text-transform: uppercase;
			&.manager {
				color: $teal;
			}
			&.facilitator {
				color: $orange1;
			}
			&.technical {
				color: $olive;
			}
		}

		.location, .description {
			font-size: 13px;
		}

		.description {
			margin-top: 20px;
		}
		.social-media {
			margin-top: 20px;
			background: $teal;
			text-align: center;
			padding: 10px 0 3px;
			span {
				margin-right: 10px;
				color: white;
				font-size: 20px;
			}
			@media(min-width: $tablet-breakpoint) {
				background: none;
				text-align: left;
				span {
					&.mail {
						color: #cccccc;
					}
					&.facebook {
						color: #4267b2;
					}
					&.twitter {
						color: #28a1f4;
					}
					&.instagram {
						color: #C13584;
					}
				}
			}
			
		}
	}
	.grid {
		margin: -80px auto 0;
		display: grid;
		.picture {
			overflow: hidden;
			position: relative;
			height: 350px;
			max-width: 400px;
			margin-left: auto;

			&.avatar {
				background: #DADADB;
				color: #f5f5f5;
				padding: 50px 0;
				text-align: center;
				svg {
					height: 350px;
					max-width: 400px;
					margin-left: auto;
					max-width: 95%;
				}
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
			
			.flag {
				position: absolute;
				top: 25px;
				left: 25px;
				width: 45px;
				height: 45px;
				overflow: hidden;
				border-radius: 100%;
			}
		}
		@media(min-width: $tablet-breakpoint) {
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
			grid-gap: 20px;
			max-width: 90%;
			.info {
				padding: 100px 20px 20px;
			}
			.picture {
				border-radius: 50px;
			}
		}

		@media(max-width: $tablet-breakpoint) {
			.picture {
				max-width: initial;
				margin-left: 0;
			}
		}

		@media(min-width: $laptop-breakpoint) {
			max-width: 70%;
		}
	}
	@media(max-width: 500px) {
		padding-top: 60px;
	}
}

.profile-page {
	.picture {
		background-color: rgba(0,0,0,0.3);
	}
	.info {
		form {
			position: relative;
		}
		.edit-btn, .change-password {
			cursor: pointer;
			color: $pink;
			font-size: 13px;
			font-family: $latomedium;
		}

		.form-container-editable {
			display: none;

			textarea#description {
				height: 100px;
			}

			
			#addMedia {
				position: relative;

				input {
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;
					width: 100%;
					height: 100%;
					cursor: pointer;
					font-size: 0;
				}
			}
		}

		&.edit {
			.form-container {
				display: none;
			}

			.form-container-editable {
				display: block;
			}
		}
	}
}

.resource-page {
	padding-top: 80px;
	.info {
		padding: 20px!important;
		.btn {
			margin-bottom: 10px;
		}
	}
	.role {
		color: $teal;
	}
	img {
		max-width: 100%;
		margin: 20px auto;
	}
}

.button-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	margin-top: 20px;
	max-width: 400px;
	margin-left: auto;
}
textarea {
	resize: none!important;
}
.comment-grid {
	display: grid;
	grid-template-columns: 35px 1fr;
	grid-gap: 15px;
	margin-bottom: 20px;
	
	.image {
		width: 35px!important;
		height: 35px!important;
		border-radius: 3px;
		overflow: hidden;
		margin-bottom: 0!important;
		
		&.avatar {
			background: #DADADB;
			color: #f5f5f5;
			text-align: center;
			svg {
				max-width: 85%;
				margin-top: 5px;
			}
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover!important;
			object-position: center!important;
		}
	}
	
	.info {
		display: grid;
    	grid-template-columns: 1fr;//auto 30px;
		.info-grid{
			.name {
				font-family: $latoheavy;
				margin-right: 10px;
				text-transform: capitalize;
			}
			.time {
				font-size: 12px;
				color: $black-4;
			}
			.media {
					
				.img-container {
					width: 200px;
					height: 200px;
					display: grid;
					align-items: center;
					justify-content: center;
					background-color: rgba(0,0,0,0.3);
				}

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
		form{
			display: flex;
			.delete-container{
				align-self: center;
				button{
					background: none;
					border: none;
				}
				i{
					cursor: pointer;
					&:hover{
						opacity: .7;
					}
				}
			}
		}
	}
}
.select2-container{
	z-index: 10000;
}
.resources-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 330px));
	grid-gap: 30px;

	.card {
		.card-header {
			min-height: 150px;
		}
	}
}

.scrollDown {
	cursor: pointer;
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}
.svg-inline--fa.fa-w-16 {
    width: 0.7em;
}
svg:not(:root) {
    overflow: hidden;
}
.fa-2x {
    font-size: 2em;
}
.share-story-banner {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 30px;
	.share-story-image {
		cursor: pointer;
		display: inline-block;
		button {
			background: #fff;
			border-radius: 5px;
			font-size: 24px;
			padding: 0 8px;
			position: relative;
			cursor: pointer;
			input {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
				font-size: 0;
			}
		}
	}
	.share-story-img {
		max-width: 100%;
		max-height: 200px;
		object-fit: contain;
	}
}

.media-drive {
	margin-top: 50px;
}

.video {
	.ytp-chrome-top, .ytp-pause-overlay {
		display: none!important;
	}
}

.fadeIn {
	animation: fadeIn .5s ease-in;
	opacity: 0;
	animation-fill-mode: forwards;
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.jumping-loader {
	display: flex;
	align-items: center;
	justify-content: center;

	.jumping-circles {
		display: flex;
		transform: translateY(5px);
		padding: 3px 0;

		div {
			animation-name: jumpCirclesJump;
			animation-duration: 1s;
			animation-iteration-count: infinite;
			height: 10px;
			width: 10px;
			background: #ea7a86;
			border-radius: 100%;
			margin: 0 10px;

			&:nth-child(2) {
				animation-delay: 0.3s;
				background: #e0c288;
			}

			&:last-child {
				animation-delay: 0.6s;
				background: #90b7a7;
			}
		}
	}

	&.full-page {
		background-color: rgba(255, 255, 255, 0.5);
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99999;
	}

	@keyframes jumpCirclesJump {
		from {
			transform: translateY(0px);
		}
		
		50% {
			transform: translateY(-10px);
		}

		to {
			transform: translateY(0px);
		}
	}
}
@media(min-width: $tablet-breakpoint) {
	.modal {
		background: rgba(255,255,255, 0.5)!important;
		.white-bg {
			background: white;
		}
		.modal-header {
			position: relative;
		}
		.modal-dialog {
			background: none;
			margin: auto!important;
			display: grid;
			align-items: center;
		}
		.modal-content {
			height: auto!important;
		}

	}
}

@media(max-width: $tablet-breakpoint) {
	.modal {
		.white-bg {
			height: 100%!important;
		}
	}
}

.capitalize {
	text-transform: capitalize;
}

.forgot-password-page {
	min-height: 74vh;
	display: grid;
	align-items: center;
	padding: 0 20px;
	.login-card	{
		max-width: 350px;
		width: 100%;
		margin: 30px auto;
		border-radius: 35px;
		.login-header {
			border-top-left-radius: 35px;
			border-top-right-radius: 35px;
			border: 1px solid #D8D8DB;
			padding: 20px;
			.logo {
				text-align: center;
				img {
					max-height: 100px;
				}
			}
			.header {
				font-size: 28px;
				font-family: $latobold;
				text-align: center;
			}
		}
		.login-body {
			padding: 25px;
			border: 1px solid #D8D8DB;
			border-top: none;
			background: #ececec;
			border-bottom-left-radius: 35px;
			border-bottom-right-radius: 35px;
			.form-group {
				margin-bottom: 20px;
				.form-control {
					background: white;
					border-radius: 35px;
					height: 42px;
					padding: 10px 20px;
					border: 1px solid #707070;
					&:focus { 
						box-shadow: none;
					}
				}
			}
			.btn {
				width: 100%;
			}
		}
	}
}
.split {
	display: grid;
	grid-template-columns: 1fr 1fr;
	.cta {
		justify-content: flex-end
	}
}

@media screen and (max-width: 630px) {
	.signup-page {
		.house-rules{
			max-width: 300px;
			padding: 0 15px;
			.house-rules-grid{
				grid-template-columns: 1fr;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.container-1000{
		max-width: 1170px;
	}
}
@media screen and (max-width: 992px) {
	.container-1000{
		max-width: 1170px;
	}
}
@media screen and (max-width: 768px) {
	.container-1000{
		max-width: 970px;
	}
}
@media screen and (max-width: 1200px) {
	.container-1000{
		max-width: 750px;
	}
}

.flex-form {
	display: flex;
	> *:first-child {
		margin-right: 5px;
	}
}
@font-face {
    font-family: 'latoblack';
    src: url('../fonts/lato-black-webfont.woff2') format('woff2'),
         url('../fonts/lato-black-webfont.woff') format('woff'),
         url('../fonts/Lato-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('../fonts/lato-bold-webfont.woff2') format('woff2'),
         url('../fonts/lato-bold-webfont.woff') format('woff'),
         url('../fonts/Lato-Bold.ttf') format('truetype');
         font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latosemibold';
    src: url('../fonts/lato-semibold-webfont.woff2') format('woff2'),
         url('../fonts/lato-semibold-webfont.woff') format('woff'),
         url('../fonts/Lato-Semibold.ttf') format('truetype');
         font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latomedium';
    src: url('../fonts/lato-medium-webfont.woff2') format('woff2'),
         url('../fonts/lato-medium-webfont.woff') format('woff'),
         url('../fonts/Lato-Medium.ttf') format('truetype');
         font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff'),
         url('../fonts/Lato-Regular.ttf') format('truetype');
         font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoheavy';
    src: url('../fonts/lato-heavy-webfont.woff2') format('woff2'),
         url('../fonts/lato-heavy-webfont.woff') format('woff'),
         url('../fonts/Lato-Heavy.ttf') format('truetype');
         font-weight: normal;
    font-style: normal;

}